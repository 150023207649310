import { render, staticRenderFns } from "./QQElencoDocumentiPratica.vue?vue&type=template&id=4925dc90&"
import script from "./QQElencoDocumentiPratica.vue?vue&type=script&lang=js&"
export * from "./QQElencoDocumentiPratica.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QTabs,QTab,QTabPanels,QTabPanel,QList,QItem,QItemSection,QAvatar,QItemLabel,QSpinnerHourglass});qInstall(component, 'directives', {Ripple});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4925dc90')) {
      api.createRecord('4925dc90', component.options)
    } else {
      api.reload('4925dc90', component.options)
    }
    module.hot.accept("./QQElencoDocumentiPratica.vue?vue&type=template&id=4925dc90&", function () {
      api.rerender('4925dc90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/QQElencoDocumentiPratica.vue"
export default component.exports