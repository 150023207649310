var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.is_mounted
      ? _c(
          "div",
          [
            _c("h5", [_vm._v(_vm._s(_vm.title))]),
            _c(
              "q-card",
              { attrs: { flat: "" } },
              [
                _c(
                  "q-tabs",
                  {
                    attrs: { "inline-label": "" },
                    model: {
                      value: _vm.tab,
                      callback: function ($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab",
                    },
                  },
                  [
                    _c("q-tab", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showContratti,
                          expression: "showContratti",
                        },
                      ],
                      attrs: { name: "contratti_pdf", label: "PDF Contratti" },
                    }),
                    _c("q-tab", {
                      attrs: {
                        name: "documenti_allegati",
                        label: "Documenti allegati",
                      },
                    }),
                    _c("q-tab", {
                      attrs: {
                        name: "documenti_contratti",
                        label: "Set Informativi",
                      },
                    }),
                    _c("q-tab", {
                      attrs: { name: "simpli_pdf", label: "Simpli" },
                    }),
                  ],
                  1
                ),
                _c(
                  "q-tab-panels",
                  {
                    attrs: { animated: "" },
                    model: {
                      value: _vm.tab,
                      callback: function ($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab",
                    },
                  },
                  [
                    _c(
                      "q-tab-panel",
                      { attrs: { name: "documenti_allegati" } },
                      [
                        _c("div", { staticClass: "text-h6" }, [
                          _vm._v("Documenti Allegati"),
                        ]),
                        _vm._v(" Clicca su un documento per scaricarlo "),
                        _c("br"),
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { separator: "", bordered: "" } },
                          _vm._l(
                            _vm.elenco_documenti.documenti_proposta.documenti,
                            function (documento, index) {
                              return _c(
                                "q-item",
                                {
                                  directives: [
                                    { name: "ripple", rawName: "v-ripple" },
                                  ],
                                  key: index,
                                  attrs: { clickable: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.onScaricaDocumentoDaUrl(
                                        documento.descrizione,
                                        documento.path
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "q-item-section",
                                    { attrs: { avatar: "" } },
                                    [
                                      _c("q-avatar", {
                                        attrs: {
                                          color: "orange-3",
                                          "text-color": "black",
                                          icon: "mdi-text-box-check-outline",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    [
                                      _c("q-item-label", [
                                        _vm._v(_vm._s(documento.descrizione)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    { attrs: { avatar: "" } },
                                    [
                                      _c("q-avatar", {
                                        attrs: {
                                          color: "orange-3",
                                          "text-color": "black",
                                          icon: "mdi-download",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "q-tab-panel",
                      { attrs: { name: "documenti_contratti" } },
                      [
                        _c("div", { staticClass: "text-h6" }, [
                          _vm._v("Documenti specifici dei contratti"),
                        ]),
                        _vm._v(" Clicca su un documento per scaricarlo "),
                        _c("br"),
                        _c("br"),
                        _vm._l(
                          _vm.getProdottiSelezionati,
                          function (prodotto, index) {
                            return _c(
                              "q-list",
                              {
                                key: index,
                                staticClass: "q-mb-md",
                                attrs: { separator: "", bordered: "" },
                              },
                              [
                                _c(
                                  "q-item-label",
                                  { attrs: { header: "" } },
                                  [
                                    _c("q-avatar", {
                                      attrs: {
                                        color: "orange-3",
                                        "text-color": "black",
                                        icon: "mdi-check-outline",
                                        size: "36px",
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(prodotto.label) + " "),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  _vm.getTariffaSelezionata(prodotto),
                                  function (tariffa, index_tariffa) {
                                    return _c(
                                      "q-item",
                                      { key: index_tariffa },
                                      [
                                        _c(
                                          "q-list",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: { separator: "" },
                                          },
                                          [
                                            _c(
                                              "q-item",
                                              [
                                                _c(
                                                  "q-item-section",
                                                  [
                                                    _c("q-item-label", [
                                                      _vm._v(" Compagnia "),
                                                    ]),
                                                    _c(
                                                      "q-item-label",
                                                      {
                                                        attrs: { caption: "" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              tariffa.compagnia
                                                            ) +
                                                            " - " +
                                                            _vm._s(
                                                              tariffa.label
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "q-item-section",
                                                  [
                                                    _c("q-item-label", [
                                                      _vm._v(" Tariffa "),
                                                    ]),
                                                    _c(
                                                      "q-item-label",
                                                      {
                                                        attrs: { caption: "" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              tariffa.tariffa
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._l(
                                              _vm.getElencoDocumenti(tariffa),
                                              function (
                                                documento,
                                                index_documento
                                              ) {
                                                return _c(
                                                  "q-item",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "ripple",
                                                        rawName: "v-ripple",
                                                      },
                                                    ],
                                                    key: index_documento,
                                                    attrs: { clickable: "" },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.onScaricaDocumentoDaUrl(
                                                          documento.id,
                                                          documento.path
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "q-item-section",
                                                      [
                                                        _c("q-item-label", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                documento.descrizione
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      { attrs: { avatar: "" } },
                                                      [
                                                        _c("q-avatar", {
                                                          attrs: {
                                                            color: "orange-3",
                                                            "text-color":
                                                              "black",
                                                            icon: "mdi-download",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _c(
                      "q-tab-panel",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showContratti,
                            expression: "showContratti",
                          },
                        ],
                        attrs: { name: "contratti_pdf" },
                      },
                      [
                        _c(
                          "q-list",
                          {
                            staticClass: "q-mb-md",
                            attrs: { separator: "", bordered: "" },
                          },
                          _vm._l(
                            _vm.elenco_contratti_pdf,
                            function (contratto_pdf, index_pdf) {
                              return _c(
                                "q-item",
                                {
                                  directives: [
                                    { name: "ripple", rawName: "v-ripple" },
                                  ],
                                  key: index_pdf,
                                  attrs: { clickable: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.onScaricaDocumentoDaUrl(
                                        contratto_pdf.tariffa,
                                        contratto_pdf.url
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "q-item-section",
                                    [
                                      _c("q-item-label", [
                                        _vm._v(" Compagnia "),
                                      ]),
                                      _c(
                                        "q-item-label",
                                        { attrs: { caption: "" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(contratto_pdf.compagnia) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    [
                                      _c("q-item-label", [
                                        _vm._v(" Prodotto "),
                                      ]),
                                      _c(
                                        "q-item-label",
                                        { attrs: { caption: "" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                contratto_pdf.prodotto_id
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    [
                                      _c("q-item-label", [
                                        _vm._v(" Nome del file "),
                                      ]),
                                      _c(
                                        "q-item-label",
                                        { attrs: { caption: "" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(contratto_pdf.filename) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    { attrs: { avatar: "" } },
                                    [
                                      _c("q-avatar", {
                                        attrs: {
                                          color: "orange-3",
                                          "text-color": "black",
                                          icon: "mdi-download",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "q-tab-panel",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showSimpli,
                            expression: "showSimpli",
                          },
                        ],
                        attrs: { name: "simpli_pdf" },
                      },
                      [
                        _c(
                          "q-list",
                          {
                            staticClass: "q-mb-md",
                            attrs: { separator: "", bordered: "" },
                          },
                          _vm._l(
                            _vm.elenco_contratti_pdf,
                            function (contratto_pdf, index_pdf) {
                              return _c(
                                "q-item",
                                {
                                  directives: [
                                    { name: "ripple", rawName: "v-ripple" },
                                  ],
                                  key: index_pdf,
                                  attrs: { clickable: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.onScaricaDocumentoDaUrl(
                                        contratto_pdf.tariffa,
                                        contratto_pdf.url
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "q-item-section",
                                    [
                                      _c("q-item-label", [
                                        _vm._v(" Compagnia "),
                                      ]),
                                      _c(
                                        "q-item-label",
                                        { attrs: { caption: "" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(contratto_pdf.compagnia) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    [
                                      _c("q-item-label", [
                                        _vm._v(" Prodotto "),
                                      ]),
                                      _c(
                                        "q-item-label",
                                        { attrs: { caption: "" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                contratto_pdf.prodotto_id
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    [
                                      _c("q-item-label", [
                                        _vm._v(" Nome del file "),
                                      ]),
                                      _c(
                                        "q-item-label",
                                        { attrs: { caption: "" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(contratto_pdf.filename) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-section",
                                    { attrs: { avatar: "" } },
                                    [
                                      _c("q-avatar", {
                                        attrs: {
                                          color: "orange-3",
                                          "text-color": "black",
                                          icon: "mdi-download",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c("br"),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.is_loading,
            expression: "!is_loading",
          },
        ],
        staticClass:
          "text-center justify-center q-pa-md q-gutter-sm fixed-center",
        staticStyle: { "z-index": "1000" },
      },
      [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }